import "./Footer.css";
import img1 from "../../assets/main-logo.webp";

import { Link } from "react-router-dom";
import SocialMedia from "../WhatsApp/SocialMedia";
import { Helmet } from "react-helmet";

export default function Footer() {
  const d = new Date();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This will animate the scroll
    });
  };
  return (
    <div className="p-2 bg-black ">
      <Helmet>
        <meta
          name="keywords"
          content="
            Jodhpur sightseeing tours, Jodhpur day trips, Jodhpur city tours, Private car rentals Jodhpur, Jodhpur heritage tours,
            Jodhpur cultural experiences, Jodhpur taxi services, Jodhpur travel packages, Jodhpur historical sites, Jodhpur desert tours,
            Jodhpur adventure tours, Jodhpur airport transfers, Jodhpur guided tours, Jodhpur photography tours, Jodhpur local tours,
            Jodhpur palaces tour, Jodhpur blue city tour, Jodhpur fort tours, Jodhpur countryside drives, Jodhpur food tours,
            Jodhpur sunset tours, Jodhpur heritage car rentals, Jodhpur family tours, Jodhpur off-road adventures, Jodhpur sightseeing by car,
            Jodhpur custom tours, Jodhpur royal heritage tours, Jodhpur half-day tours, Jodhpur eco-friendly tours, Jodhpur weekend getaways,
            Jodhpur scenic drives, Jodhpur local attractions, Jodhpur market tours, Jodhpur temple tours, Jodhpur adventure travel,
            Jodhpur photography trips, Jodhpur family-friendly tours, Jodhpur guided road trips, Jodhpur village tours,
            Jodhpur heritage walk and drive, Jodhpur cultural road trips, Jodhpur travel guides, Rajasthan desert safaris,
            Rajasthan heritage tours, Rajasthan luxury car rentals, Rajasthan cultural experiences, Rajasthan city tours,
            Rajasthan adventure tours, Rajasthan family travel, Rajasthan wildlife tours, Rajasthan historical sites,
            Rajasthan village tours, Rajasthan photography tours, Rajasthan custom itineraries, Rajasthan road trips
          "
        />
        <meta
          name="description"
          content="Rajasthan Trips offers premium taxi services in Jodhpur, including airport transfers and city tours. Book a taxi today for a hassle-free experience."
        />
        <title>Taxi Service in Jodhpur - Rajasthan Trips</title>
      </Helmet>
      <div className="footer-container p-14">
        <div>
          <div>
            <img
              src={img1}
              alt="car tours jodhpur logo "
              width={250}
              height={200}
            />
            <p>
              Welcome to the official website of Rajasthan Trips. We offer
              jodhpur tour and travlers
            </p>
          </div>
        </div>

        <div>
          <p className="decoration-dashed	text-white mt-10">Support</p>
          <p
            className=" hover:cursor-pointer mt-2"
            onClick={() => {
              window.location.href = "tel:+919001502506";
            }}
          >
            Call Us
          </p>
          <p
            className="hover:cursor-pointer"
            onClick={() => {
              window.open("https://wa.me/+919001502506", "_blank");
            }}
          >
            whats App
          </p>
        </div>

        <div>
          <p className="decoration-dashed	text-white mt-10"> About Us</p>
          <Link className="hover:cursor-pointer" to="/about-us">
            <p className="mt-2" onClick={scrollToTop}>
              Our Story
            </p>
          </Link>
          <Link to="/privacy-policy">
            <p onClick={scrollToTop}>Privacy Policy</p>
          </Link>
          <Link to="/cancellation-policy">
            <p>Cancellations Policy</p>
          </Link>
          <Link to="/term-and-conditions">
            <p>Term and condition</p>
          </Link>
          <Link to="/refund-policy">
            <p onClick={scrollToTop}>Refund policy</p>
          </Link>
        </div>
        <div>
          <p className="decoration-dashed	text-white mt-10">Contact Info</p>
          <p className="mt-2">
            Near Airport Road 5 Batti Circle Jodhpur , 342001
          </p>
          <p>+91 9001502506</p>

          <p>toursshreeganesh5@gmail.com</p>
        </div>
      </div>
      <div>
        <SocialMedia />
      </div>
      <div className="bg-black text-white text-center">
        <p>© {d.getFullYear()} Taxi service in jodhpur</p>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import img404 from "../../assets/404.jpg";

export default function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000); // Redirect after 2 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <div className="w-full block relative flex justify-center">
      <Helmet>
        <title>404 Page Not Found</title>
      </Helmet>
      <img src={img404} alt="404 Page Not Found" width={512} height={700} />
    </div>
  );
}

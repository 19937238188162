import logo from "../../assets/main-logo-white.webp";
import NavBarMainList from "./NavBarMainList";
import { Link } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

import { RiMenu3Fill } from "react-icons/ri";
import { MdArrowDropDown } from "react-icons/md";
import "./MainNevbar.css";
import instaLogo from "../../assets/insta-logo.webp";
import { useState } from "react";
import { Helmet } from "react-helmet";

const MainNevbar = () => {
  const [isMenuOpen, setisMenuOpened] = useState(false);
  const [isTaxiServicesOpen, setIsTaxiServicesOpen] = useState(false);
  const [isSightSeenOpen, setIsSightSeenOpen] = useState(false);

  const toggleMenu = () => {
    setisMenuOpened(!isMenuOpen);
  };

  const toggleTaxiServices = () => {
    setIsTaxiServicesOpen(!isTaxiServicesOpen);
    if (isSightSeenOpen) setIsSightSeenOpen(false);
  };

  const toggleSightSeen = () => {
    setIsSightSeenOpen(!isSightSeenOpen);
    if (isTaxiServicesOpen) setIsTaxiServicesOpen(false);
  };

  const closeMenu = () => {
    setisMenuOpened(false);
    setIsTaxiServicesOpen(false);
    setIsSightSeenOpen(false);
  };

  /* function FbBoxOpen() {
    window.open(
      "https://www.facebook.com/share/AE3w4KDPuhyL7bF5/?mibextid=qi2Omg",
      "_blank"
    );
  }

  function intaBoxOpen() {
    window.open(
      "https://www.instagram.com/cartoursjodhpur/?igsh=OTkwZmVyYTc5c3Ru",
      "_blank"
    );
  } */

  return (
    <nav>
      <div>
      <Helmet>
        <title>Car Tours Jodhpur | Explore Jodhpur's Best Attractions with Taxi Services</title>
        <meta 
          name="description" 
          content="Explore the top attractions of Jodhpur, including Mehrangarh Fort, Umaid Bhawan Palace, Jaswant Thada, and more, with convenient and reliable car tours in Jodhpur. Book your taxi service today for a hassle-free experience." 
        />
        <meta 
          name="keywords" 
          content="Car Tours Jodhpur, car, cab, and taxi services in Jodhpur, air-conditioned cars and cabs, comfortable and relaxed travel, Event & Wedding Cabs, specialized cab services, tour taxi services, local and outstation tours, cab in Jodhpur, car, cab, or taxi online, exploring the city by car, Car Hire in Jodhpur, comfortable and convenient travel, Cab Service in Jodhpur, Taxi Services in Jodhpur, easy taxi booking, affordable cab services, Car Rental Jodhpur, Flexible car rental, Car Rental Innova Crysta Jodhpur, Rent the premium cars, best cab service, best cab service provider in Jodhpur, professional taxi service, Rajasthan trips, cab on rent in Jodhpur, affordable cab hire in Jodhpur, private cab rental services, Rajasthan tour package" 
        />
      </Helmet>

      </div>
      <div className="main-menu">
        <div className="flex bg-black w-full text-white p-2 items-center justify-between md:justify-end">
          <p className="text-xs md:text-sm md:mr-4">
            Call us for Booking +919001502506
          </p>
          <span className="text-xs md:text-sm p-1 rounded-lg bg-orange-500">
            &#116;&#111;&#117;&#114;&#115;&#115;&#104;&#114;&#101;&#101;&#103;&#97;&#110;&#101;&#115;&#104;&#53;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
          </span>

          <a
            href="https://www.facebook.com/share/AE3w4KDPuhyL7bF5/?mibextid=qi2Omg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="w-8 h-10 text-blue-500 ml-1 hover:cursor-pointer" />
          </a>

          <a
            href="https://www.instagram.com/cartoursjodhpur/?igsh=OTkwZmVyYTc5c3Ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instaLogo}
              alt="social-media-icon"
              width={35}
              className="ml-[4px] hover:cursor-pointer"
            />
          </a>
        </div>

        <div className="sub-main">
          <div></div>

          <Link to="/">
            <div>
              <img src={logo} alt="car tour jodhpur" width={180} height={100} />
            </div>
          </Link>
          <div>
            <div className="">
              <NavBarMainList />
            </div>
          </div>
        </div>

        {/* Mobile menu */}

        <div className="mobile-submenu-maincontainer">
          <div className="menu-container">
            <div className="image-logo">
              <Link to="/" onClick={closeMenu}>
                <img
                  src={logo}
                  alt="car tour jodhpur"
                  width={100}
                  height={140}
                />
              </Link>
            </div>
            <div className="burger-icon">
              <RiMenu3Fill
                style={{ cursor: "pointer", width: "25px", height: "30px" }}
                onClick={toggleMenu}
              />
            </div>
          </div>

          {isMenuOpen && (
            <div className="menu-box border">
              <div className="flex flex-col gap-4 text-2xl">
                <div className="custom-font">
                  <Link to="/about-us" onClick={closeMenu}>
                    About Us
                  </Link>
                </div>

                <div className="custom-font" onClick={toggleTaxiServices}>
                  <span>
                    Taxi Services <MdArrowDropDown className="inline" />
                  </span>
                  {isTaxiServicesOpen && (
                    <ul className="block ml-4 font-bold text-white">
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/jaipur" onClick={closeMenu}>
                          Jaipur
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/jodhpur" onClick={closeMenu}>
                          Jodhpur
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/Balotra" onClick={closeMenu}>
                          Balotra
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/Barmer" onClick={closeMenu}>
                          Barmer
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/Ajmer" onClick={closeMenu}>
                          Ajmer
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/Pali" onClick={closeMenu}>
                          Pali
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/taxi-services/Osian" onClick={closeMenu}>
                          Osian
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div className="custom-font" onClick={toggleSightSeen}>
                  <span>
                    Sight Seen <MdArrowDropDown className="inline" />
                  </span>
                  {isSightSeenOpen && (
                    <ul className="ml-4 text-white custom-font">
                      <li className="border-b border-blue-400">
                        <Link to="/onewaytaxi/:Jodhpur" onClick={closeMenu}>
                          Jodhpur
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/onewaytaxi/:Jaipur" onClick={closeMenu}>
                          Jaipur
                        </Link>
                      </li>
                      <li className="border-b border-blue-400">
                        <Link to="/onewaytaxi/:Udaipur" onClick={closeMenu}>
                          Udaipur
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                <div className="custom-font">
                  <Link to="/one-way-taxi" onClick={closeMenu}>
                    One Way Taxi
                  </Link>
                </div>

                <Link to="/car-rent-jodhpur" onClick={closeMenu}>
                  <div className="custom-font">Jodhpur Cab</div>
                </Link>

                <div className="custom-font">
                  <Link to="/contact-us" onClick={closeMenu}>
                    Contact Us
                  </Link>
                </div>

                <div className="custom-font">
                  <Link to="/blogs" onClick={closeMenu}>
                    Blogs
                  </Link>
                </div>

                
              </div>
            </div>
          )}
          
        </div>
      </div>
    </nav>
  );
};

export default MainNevbar;

import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";

import FullScreenLoader from "../fullscreenloader/FullScreenLoader";

const NavigateJodhpurWonders = lazy(() =>
  import("./blog-lists/navigarejodhpurwonders/NavigateJodhpurWonders")
);

const Journetintoadventure = lazy(()=>
import("./blog-lists/journetintoadventure/Journetintoadventure"));

const blogRoutes = [
  {
    path: "/Navigate-the-Wonders-of-Jodhpur",
    component: NavigateJodhpurWonders,
  },
  {
    path: "/Cab-Services-in-Jodhpur-That-Turn-Every-Journey-Into-an-Adventure",
    component: Journetintoadventure,
  },
];
const BlogRoutes = () =>
    blogRoutes.map(({ path, component: Component }) => (
      <Route
        key={path}
        path={path}
        element={
          <Suspense fallback={<FullScreenLoader />}>
            <Component />
          </Suspense>
        }
      />
    ));
  
  export default BlogRoutes;
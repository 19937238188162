import React, { useEffect, useState } from "react";
import { fetchBlogData } from "../blog-api/BlogApi";
import MainBlogContainer from "./MainBlogContainer";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  const [blogData, setBlogData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // Number of posts per page

  useEffect(() => {
    const getData = async () => {
      const data = await fetchBlogData();
      setBlogData(data);
    };
    getData();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Calculate the blog posts to display on the current page
  const startIndex = (currentPage - 1) * postsPerPage;
  const currentPosts = blogData.slice(startIndex, startIndex + postsPerPage);

  return (
    <div>
      <Helmet>
        <title>Blogs - Car Tours Jodhpur</title>
        <meta
          name="description"
          content="Read the latest travel blogs and guides from Car Tours Jodhpur. Get insights on top tourist destinations, road trips, and travel tips."
        />
        <link rel="canonical" href="https://www.cartoursjodhpur.com/blogs" />
      </Helmet>
      <MainBlogContainer blogData={currentPosts} />
      {/* Pagination Component */}
      <div className="flex justify-center mt-6">
        <Pagination
          count={Math.ceil(blogData.length / postsPerPage)} // Total pages
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{
            marginBottom: "10px",
          }}
        />
      </div>
    </div>
  );
};

export default BlogPage;
